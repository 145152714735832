@font-face {
	font-family: 'HanleyPro';
	font-weight: 400;
	src: url('../fonts/3AB291_0_0.eot');
	src: url('../fonts/3AB291_0_0.eot?#iefix') format('embedded-opentype'), url('../fonts/3AB291_0_0.woff2') format('woff2'), url('../fonts/3AB291_0_0.woff') format('woff'); }

@font-face {
	font-family: 'TwCenMT';
	font-weight: 400;
	src: url('../fonts/TwCenMT-Regular.eot');
	src: url('../fonts/TwCenMT-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/TwCenMT-Regular.woff2') format('woff2'), url('../fonts/TwCenMT-Regular.woff') format('woff'); }
@font-face {
	font-family: 'TwCenMT';
	font-weight: 600;
	src: url('../fonts/TwCenMT-Bold.eot');
	src: url('../fonts/TwCenMT-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/TwCenMT-Bold.woff2') format('woff2'), url('../fonts/TwCenMT-Bold.woff') format('woff'); }
@font-face {
	font-family: 'TwCenMT';
	font-weight: 400;
	font-style: italic;
	src: url('../fonts/TwCenMT-Italic.eot');
	src: url('../fonts/TwCenMT-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/TwCenMT-Italic.woff2') format('woff2'), url('../fonts/TwCenMT-Italic.woff') format('woff'); }
@font-face {
	font-family: 'TwCenMT';
	font-weight: 600;
	font-style: italic;
	src: url('../fonts/TwCenMT-BoldItalic.eot');
	src: url('../fonts/TwCenMT-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/TwCenMT-BoldItalic.woff2') format('woff2'), url('../fonts/TwCenMT-BoldItalic.woff') format('woff'); }
